.orders {
  padding: 0 10px;
  .filter-box {
    margin-bottom: 20px;
  }
  .ant-space {
    width: 100%;
    .infinite-scroll-component {
      display: flex;
      flex-direction: column;
      gap: 16px;
      .ant-collapse {
        font-size: 12px;
        .ant-collapse-header {
          display: flex;
          align-items: flex-start;
          padding: 8px;

          .ant-collapse-arrow {
            margin: 0;
            margin-top: 5px;
            margin-right: 5px;
          }
          .ant-collapse-header-text {
            width: calc(100% - 17px);
            .priority {
              text-align: center;
            }
            .header-panel {
              display: grid;
              grid-template-columns: repeat(3, 1fr);
              align-items: center;
              row-gap: 8px;
              column-gap: 2px;
              .status-order {
                width: 100%;
                padding: 4px;
                text-align: center;
                border-radius: 8px;
              }
              .status-order.waiting-for-shipping {
                background: #e9eef5;
                color: #5b6673;
              }
              .status-order.delivery.waiting {
                background: #ffd9be;
                color: #ef9351;
              }
              .status-order.delivery.confirm-picked {
                background: #e7f0ff;
                color: #2246dc;
              }
              .status-order.completed {
                background: #edf9f0;
                color: #00ab78;
              }
              .steps {
                grid-row-start: auto;
                grid-column-start: span 3;

                .ant-steps-item-title {
                  width: 100%;
                  padding: 0;
                }
                .title {
                  color: #262f3b;
                  display: flex;
                  gap: 8px;
                  .address {
                    width: 65%;
                  }
                  .address.receiver {
                    display: flex;
                    flex-direction: column;
                    .phone {
                      color: #0064f0;
                    }
                  }
                  .time {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    width: calc(35% - 8px);
                    font-size: 12px;
                  }
                  .time.receiver {
                    color: #9cadc3;
                  }
                }
              }
              .button {
                grid-row-start: auto;
                grid-column-start: span 3;
                display: flex;
                justify-content: flex-end;
                gap: 16px;
              }
              .description {
                grid-row-start: auto;
                grid-column-start: span 3;
                display: grid;
                grid-template-columns: 0.5fr 1.5fr;
                row-gap: 8px;
              }
            }
          }
        }
        .ant-collapse-content {
          .body-panel {
            margin-top: 8px;
            padding: 0px 10px 10px 10px;
            display: flex;
            flex-direction: column;
            gap: 8px;
            .title {
              font-size: 14px;
            }
            .card-product {
              display: grid;
              grid-template-columns: 1.75fr 0.25fr;
              background-color: #e9eef5;
              padding: 8px;
              border-radius: 8px;
              b:nth-child(1) {
                font-size: 14px;
              }
              .amount {
                text-align: end;
                font-style: italic;
                color: #2246dc;
              }
              .product-code,
              .product-attribute {
                grid-row-start: auto;
                grid-column-start: span 2;
                margin-left: 16px;
              }
              .product-code {
                font-style: italic;
              }
            }
          }
        }
      }
    }
  }
}
